<template>
  <transition name="slide-fade">
    <div ref="chaptersContainer" class="chapters-container" v-if="expandToc">
      <button @click.prevent.stop="expandToc = false" class="is-pulled-right delete is-large">
        Close
      </button>

      <div class="chapters-container__chapters">
        <ol>
          <li
            style="cursor: pointer"
            @click="toggleActiveChapter(item.id)"
            :key="item.id"
            v-for="item in items"
          >
            <h1
              class="title is-responsive-size"
              :class="{ 'is-active': activeSectionId == item.id }"
            >
              {{ item.sectionHeader }}
            </h1>
          </li>
        </ol>
      </div>
    </div>
  </transition>
  <div class="toc-container toc-container-desktop">
    <span
      class="toc-container-desktop__trigger"
      style=""
      :class="{ 'toc-container-desktop__trigger--expanded': expandToc }"
      @click.prevent.stop="expandToc = !expandToc"
    >
      <i class="fas fa-caret-right"></i>
      <h3
        class="expand-trigger__text"
        style="writing-mode: vertical-rl; transform: rotate(180deg); font-size: 24px"
      >
        SECTIONS
      </h3>
      <i class="fas fa-caret-right"></i>
    </span>
  </div>

  <div
    class="toc-container toc-container-mobile"
    @click.prevent.stop="onExpandToc"
    ref="mobileTriggerContainer"
  >
    <button class="button is-rounded" style="background-color: black; opacity: 0.6">
      <span class="icon is-small">
        <i class="fas fa-bars"></i>
      </span>
      <p style="color: white; opacity: 1; is-uppercase;">Sections</p>
    </button>
  </div>
</template>

<script>
import { onMounted, ref, watchEffect, watch, onBeforeUnmount } from 'vue'

export default {
  name: 'TocNavBar',
  props: {
    isActive: {
      type: Boolean,
      default: false
    },
    activeSectionId: {
      type: String,
      default: null
    },

    items: {
      type: Array,
      default: () => []
    }
  },
  emits: ['toggleActiveSection'],
  setup(_, { emit }) {
    const expandToc = ref(false)
    const chaptersContainer = ref(null)
    const mobileTriggerContainer = ref(null)
    function toggleActiveChapter(itemId) {
      emit('toggleActiveSection', itemId)
      onExpandToc()
    }
    watchEffect(() => {
      setAutoCloseListener(expandToc.value)
      //chaptersContainer.value
    })
    function setAutoCloseListener(val) {
      if (val) {
        window.addEventListener('mouseup', listenToClicksOutsideofToc)
      } else {
        window.removeEventListener('mouseup', listenToClicksOutsideofToc)
      }
    }
    onBeforeUnmount(() => {
      window.removeEventListener('mouseup', listenToClicksOutsideofToc)
    })

    function listenToClicksOutsideofToc(e) {
      if (
        !chaptersContainer.value?.contains(e.target) &&
        !mobileTriggerContainer.value?.contains(e.target)
      ) {
        expandToc.value = false
      }
    }
    function onExpandToc() {
      expandToc.value = !expandToc.value
    }
    return {
      toggleActiveChapter,
      expandToc,
      chaptersContainer,
      onExpandToc,
      mobileTriggerContainer
    }
  }
}
</script>

<style lang="scss" scoped>
.toc-container-desktop {
  font-family: 'Raleway', 'museo-sans';
  display: flex;
  margin: auto;
  justify-content: center;
  width: 5rem;
  &:hover {
    cursor: pointer;
  }
  &__trigger {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &--expanded {
      display: none;
      opacity: 0;
    }
  }

  @include until(769px) {
    display: none;
  }
}
.toc-container-mobile {
  font-family: 'Raleway', 'museo-sans';
  @include from(769px) {
    display: none;
  }
}
.expand-trigger--mb {
  display: none;
  @include until(768px) {
    z-index: 500;
    cursor: pointer;
    display: flex;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
  }
}
.is-responsive-size {
  @include fluid-property('font-size', 1.5, 1, em);
}
.is-active {
  text-decoration: underline;
}
.slide-fade-enter-active {
  transition: all 0.5s ease-out;
  position: fixed;
}
.slide-fade-enter-active + .expand-trigger {
}

.slide-fade-leave-active {
  transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
  position: fixed;
}

.slide-fade-leave-to {
  transform: translateX(-500px);
  opacity: 0;
  position: fixed;
}
.slide-fade-enter-from {
  opacity: 0;
  transform: translateX(-500px);
}
.chapters-container::-webkit-scrollbar {
  display: none;
}
.chapters-container {
  font-family: 'Raleway', 'museo-sans';
  opacity: 1;
  position: fixed;
  left: 10px;
  top: 0;
  z-index: 500;
  min-width: 20rem;
  width: 50%;
  background-color: white;
  height: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;

  overflow-y: scroll;
  &__chapters {
    color: black;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    margin: 10% 5% 5% 10%;
  }
}

ol {
  padding: 0rem 1rem;
}
li {
  margin: 1rem;
  display: list-item;
  list-style-position: outside;
  list-style-type: decimal;
}
.delete {
  background-color: transparent;
}
.delete::before,
.delete::after {
  background-color: black;
}
</style>
