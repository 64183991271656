import { ref, computed, inject } from 'vue'
import { useRoute } from 'vue-router'
import VueScrollTo from 'vue-scrollto'
import yearlyStore from '@/composables/yearlyStore'
import Report from '@/services/reports'
import { Font } from '@/services/brandKits/models'

const PREVIEW = 'PREVIEW'
const PUBLISHED = 'PUBLISHED'
export default function reportView(viewType = null) {
  const topOfPagePixel = ref(null)
  const sectionKey = 'sections'
  const tocKey = 'reportToc'
  const nextPageTrigger = ref(null)
  const showNextFooter = ref(false)
  const route = useRoute()
  const report = ref(null)
  const loadingReport = ref(true)
  const currentSectionQuery = ref(route.query.chapter)
  const { user } = yearlyStore()
  const coverSectionContainer = ref(null)
  const reportContainer = ref(null)
  const COVER_SECTION_CONTAINER_WIDTH = 1400
  const CHAPTER_CONTAINER_OFFSET = 80
  const MOBILE_WIDTH = 768
  const TABLET_WIDTH = 1279
  const nextSlideFooter = ref(null)
  const nextSlideFooterHeight = ref(0)
  const printReadyReport = ref(null)

  const upNextObserver = new window.IntersectionObserver(
    ([entry]) => {
      if (entry.isIntersecting) {
        showNextFooter.value = true
        return
      } else if (
        !entry.isIntersecting &&
        entry.intersectionRect?.top >= entry.boundingClientRect?.top
      ) {
        showNextFooter.value = true
        return
      }
      showNextFooter.value = false
      return
    },
    {
      root: null,
      threshold: 0.1 // set offset 0.1 means trigger if atleast 10% of element in viewport
    }
  )
  const sectionObserver = new window.IntersectionObserver(
    ([entry]) => {
      if (entry.isIntersecting) {
        return
      }

      return
    },
    {
      root: null,
      threshold: 0.0 // set offset 0.1 means trigger if atleast 10% of element in viewport
    }
  )
  const webReportSections = computed(() => {
    if (!report.value) {
      return null
    }
    return report.value[sectionKey].filter((section) => section.includeInWeb)
  })
  const currentSection = computed(() => {
    if (!webReportSections.value || !webReportSections.value.length) {
      return null
    }

    return currentSectionQuery.value
      ? webReportSections.value.find((s) => s.id == currentSectionQuery.value)
      : webReportSections.value[0]
  })
  const nextSection = computed(() => {
    if (!webReportSections.value || !webReportSections.value.length) {
      return null
    }
    if (currentSection.value) {
      let indexOfCurrentSection = webReportSections.value.findIndex(
        (s) => s.order == currentSection.value.order
      )
      if (indexOfCurrentSection + 1 < webReportSections.value.length) {
        return webReportSections.value[indexOfCurrentSection + 1]
      }
    }

    return null
  })
  async function initializePreivewReportRoute() {
    const { rid } = { ...route.params }
    if (rid) {
      try {
        loadingReport.value = true

        const res = await Report.api.retrieve(rid, { filters: { firebaseId: user.firebaseId } })
        report.value = res
      } catch (e) {
        console.log(e)
      } finally {
        loadingReport.value = false
      }
    } else {
      window.location.href = process.env.VUE_APP_MAIN_APP_URL
    }
  }
  async function initializeReportRoute() {
    const { userName, reportSlug } = { ...route.params }

    if (userName && reportSlug) {
      try {
        loadingReport.value = true

        const res = await Report.api.retrievePublished(userName, reportSlug)
        report.value = res
      } catch (e) {
        console.log(e)
      } finally {
        loadingReport.value = false
      }
    }
  }
  const showFooterPreview = computed(() => {
    return !user.value.isPlanPaid || (user?.value.isPlanPaid && report.value?.showFooter)
  })
  const showFooterPublished = computed(() => {
    return (
      report.value.subscriptionStatus !== 'paid' ||
      (report.value.subscriptionStatus == 'paid' && report.value.showFooter)
    )
  })
  function onNextChapter(newSectionId, elValue) {
    VueScrollTo.scrollTo(elValue)
    currentSectionQuery.value = newSectionId
  }
  const scaleBy = ref(1)
  const resizeObserver = new ResizeObserver((entries) => {
    entries.forEach((ent) => {
      const isMobile = ent.contentRect.width <= MOBILE_WIDTH

      const offset = isMobile ? 0 : 80

      scaleBy.value = determineResizeScale(ent, COVER_SECTION_CONTAINER_WIDTH, offset)
    })
  })
  function resizeViewerElHeight(height) {
    if (showNextFooter.value) {
      nextSlideFooterHeight.value = height
    }
  }

  function determineResizeScale(element, elementToResizeBase, additionalOffset = 0) {
    const rect = element.contentRect
    // if mobile or tablet scale down

    const rectWidth = rect.width - additionalOffset

    //isTabletOrMobile ? rect.width : rect.width - 80
    return rectWidth / elementToResizeBase //COVER_SECTION_CONTAINER_WIDTH
  }

  function getGroupSize(groupArray) {
    return groupArray.reduce((acc, curr) => {
      return (acc += curr.sectionHeight)
    }, 0)
  }

  function reorganizeReportForPrint(report) {
    let r = []
    let printSections = report[sectionKey].filter((s) => s.includeInPrint)

    printSections.forEach((section) => {
      r.push([section])
      let printModules = section.sections.filter((s) => s.includeInPrint)
      let childSections = sectionReducer(printModules)
      if (childSections.length) {
        r = [...r, ...childSections]
      }
    })

    printReadyReport.value = r
  }
  function sectionReducer(sections) {
    const groupHeight = (group) => {
      return group.reduce((acc, curr) => {
        return (acc += curr.sectionHeight)
      }, 0)
    }

    let reducedArray = sections.reduce((prev, curr, i) => {
      if (!prev.length) {
        prev = [[curr]]
        return prev
      }
      if (curr.sectionHeight == 800) {
        prev = [...prev, [curr]]
      } else if (
        curr.sectionHeight < 800 &&
        groupHeight(prev[prev.length - 1]) + curr.sectionHeight <= 1000
      ) {
        let updatedGroup = [...prev[prev.length - 1], curr]
        prev[prev.length - 1] = updatedGroup
      } else {
        prev = [...prev, [curr]]
      }
      return prev
    }, [])

    return reducedArray
  }

  function loadFonts(fontUrl, isCustom = false) {
    Font.create({ url: fontUrl, isCustom: isCustom }).loadFont()
  }

  function tocByViewTypeFilter(report, type) {
    const typeKey = type == 'PRINT' ? 'includeInPrint' : 'includeInWeb'
    let toc = report[tocKey].filter((toc) => toc[typeKey])

    return toc
  }

  function getTocWithPages(tocList, printReadyReport, tocPageCountOffset = 2) {
    if (!printReadyReport) {
      return []
    }

    let toc = [...tocList]
    toc.map((section) => {
      section.page =
        printReadyReport.findIndex((pagedSection) => {
          // the organized report is an array of arrays we only need the first item in the array
          return pagedSection[0].id == section.id
        }) + tocPageCountOffset
      return section
    })
    return toc
  }

  return {
    nextPageTrigger,
    showNextFooter,
    sectionObserver,
    currentSection,
    nextSection,
    route,
    report,
    loadingReport,
    currentSectionQuery,
    coverSectionContainer,
    initializePreivewReportRoute,
    initializeReportRoute,
    resizeObserver,
    showFooterPreview,
    showFooterPublished,
    topOfPagePixel,
    upNextObserver,
    onNextChapter,
    reportContainer,
    scaleBy,
    COVER_SECTION_CONTAINER_WIDTH,
    CHAPTER_CONTAINER_OFFSET,
    MOBILE_WIDTH,
    TABLET_WIDTH,
    nextSlideFooter,
    resizeViewerElHeight,
    nextSlideFooterHeight,
    getGroupSize,
    reorganizeReportForPrint,
    loadFonts,
    printReadyReport,
    tocByViewTypeFilter,
    getTocWithPages
  }
}
