<template>
  <div
    v-if="report && !loadingReport"
    style="background-color: white"
    id="report-container"
    ref="reportContainer"
  >
    <div style="" id="toolbar-container-desktop">
      <button
        v-tooltip:right.tooltip="'Print/PDF Ready Report'"
        class="button drag-handle"
        style="position: absolute; left: 15px; top: 15px"
        @click="routeToPreview"
      >
        <span class="icon is-small">
          <i class="fas fa-print fa-xs"></i>
        </span>
      </button>
      <TocNavBar
        :items="tocByViewTypeFilter(report, 'WEB')"
        :activeSectionId="currentSection?.id"
        @toggleActiveSection="onNextChapter($event, reportContainer)"
      />
    </div>
    <div style="" id="toolbar-container-mb">
      <TocNavBar
        :items="tocByViewTypeFilter(report, 'WEB')"
        :activeSectionId="currentSection?.id"
        @toggleActiveSection="onNextChapter($event, reportContainer)"
      />
      <button
        v-tooltip:right.tooltip="'Print/PDF Ready Report'"
        class="button drag-handle is-pulled-right"
        @click="routeToPreview"
      >
        <span class="icon is-small">
          <i class="fas fa-print fa-xs"></i>
        </span>
      </button>
    </div>

    <div
      style="display: flex; flex-direction: column; width: 1400px; transform-origin: left top"
      class="cover-section-container"
      :key="currentSection.id"
      v-if="currentSection"
      :id="`cover-section-${currentSection.id}`"
      ref="coverSectionContainers"
    >
      <div ref="renderedReportContainer">
        <ReportRender
          :sectionBgImageAlt="currentSection.sectionBgImageConfig.bgAlt"
          :sectionBgImageTitle="currentSection.sectionBgImageConfig.bgTitle"
          :headingFont="report.headingFont"
          :bodyFont="report.bodyFont"
          :sectionBgColor="currentSection.sectionBgColor"
          :generatedJson="currentSection.generatedJson"
          :sectionBgImage="currentSection.sectionBgImage"
          :sectionBgImageSize="currentSection.sectionBgImageConfig.size"
          :sectionHeight="currentSection.sectionHeight"
          :sectionFlex="currentSection.sectionFlex"
          :childSections="currentSection.sections"
          :sectionOrder="currentSection.order"
          :isCoverSection="currentSection.isCoverSection"
          :isLastSection="currentSection.order == report.sections.length"
          :sectionId="currentSection.id"
          :includeInWeb="currentSection.includeInWeb"
          :includeInPrint="currentSection.includeInPrint"
        />
      </div>
      <span style="" ref="nextPageTrigger"></span>
      <div>
        <NextUpFooter
          :showNextFooter="true"
          :nextSection="nextSection"
          @go-to-next-section="onNextChapter(nextSection?.id, reportContainer)"
          @next-slide-footer-height="resizeViewerElHeight($event)"
        />
      </div>
      <div ref="yearlyFooterContainer">
        <YearlyReportFooter :showFooter="showFooterPublished" />
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, computed, watchEffect, onBeforeUnmount } from 'vue'
import { useRouter } from 'vue-router'

import { Font } from '@/services/brandKits/models'

import reportView from '@/composables/reportView'
import reportFunctions, { reportSectionFunctions } from '@/composables/reportFunctions'

import CoverSectionContainer from '@/components/CoverSectionContainer'
import ReportRender from '@/components/ReportRender'
import YearlyReportFooter from '@/components/YearlyReportFooter'
import MobileChapterToc from '@/components/MobileChapterToc'
import NextUpFooter from '../../components/NextUpFooter.vue'
import TocNavBar from '../../components/TocNavBar.vue'
const PREVIEW = 'PREVIEW'
export default {
  components: {
    CoverSectionContainer,
    YearlyReportFooter,
    MobileChapterToc,
    NextUpFooter,
    TocNavBar,
    ReportRender
  },
  setup() {
    const previewContainer = ref(null)
    const coverSectionContainers = ref(null)
    const expandToc = ref(false)
    const renderedReportContainer = ref(null)
    const yearlyFooterContainer = ref(null)
    const router = useRouter()

    const {
      showNextFooter,
      nextPageTrigger,
      resizeViewerElHeight,
      route,
      report,
      loadingReport,
      currentSectionQuery,
      currentSection,
      nextSection,
      initializePreivewReportRoute,
      coverSectionContainer,
      showFooterPublished,
      topOfPagePixel,
      reportContainer,
      onNextChapter,
      resizeObserver,
      scaleBy,
      nextSlideFooter,
      nextSlideFooterHeight,
      tocByViewTypeFilter
    } = reportView(PREVIEW)

    onMounted(async () => {
      await initializePreivewReportRoute()
      if (report.value?.headingFontIsCustom) {
        Font.create({ url: report.value.headingFontUrl, isCustom: true }).loadFont()
      }
      if (report.value?.bodyFontIsCustom) {
        Font.create({ url: report.value.bodyFontUrl, isCustom: true }).loadFont()
      }
      resizeObserver.observe(reportContainer.value)
    })
    function routeToPreview(print = false) {
      let routeData = router.resolve({ name: 'PrintPreviewPage', params: { rid: report.value.id } })

      window.open(routeData.href, '_blank')
    }

    watchEffect(() => {
      if (scaleBy.value) {
        if (coverSectionContainers.value) {
          coverSectionContainers.value.style.transform = `scale(${scaleBy.value})`
          let combinedHeight =
            renderedReportContainer.value.getBoundingClientRect().height +
            yearlyFooterContainer.value?.getBoundingClientRect().height

          const altHeight =
            combinedHeight <= window.outerHeight ? combinedHeight : window.outerHeight
          reportContainer.value.style.height = `${altHeight}px`
        }
      }
    })

    onBeforeUnmount(() => {
      resizeObserver.disconnect()
    })

    return {
      report,
      loadingReport,
      expandToc,
      currentSection,
      currentSectionQuery,
      nextPageTrigger,
      showNextFooter,
      nextSection,
      showFooterPublished,
      previewContainer,
      coverSectionContainers,
      onNextChapter,
      reportContainer,
      nextSlideFooter,
      resizeViewerElHeight,
      yearlyFooterContainer,
      renderedReportContainer,
      tocByViewTypeFilter,
      routeToPreview
    }
  }
}
</script>

<style lang="scss" scoped>
.section--active {
  text-decoration: underline;
}
.top-of-page-pixel {
  position: absolute;
  width: 1px;
  height: 1px;
  top: 100px;
  left: 0;
}
.cover-section-container {
  @include from(769px) {
    margin-left: 5rem;
  }
}
#toolbar-container-desktop {
  @include from(769px) {
    display: flex;
    position: fixed;
    z-index: 999;
    margin: auto;
    background-color: white;
    box-shadow: 1px 0px 10px 0px lightgrey;
    height: 100%;
  }
  @include until(769px) {
    display: none;
  }
}
#toolbar-container-mb {
  @include from(769px) {
    display: none;
  }
  @include until(768px) {
    display: block;
    position: fixed;
    z-index: 999;
    right: 1px;
  }
}

.next-enter {
  opacity: 0;
  transform: scale3d(2, 0.5, 1) translate3d(400px, 0, 0);
}

.next-enter-to {
  transform: scale3d(1, 1, 1);
}
.next-enter-active,
.next-leave-active {
  transition: 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.next-leave {
  transform: scale3d(1, 1, 1);
}

.next-leave-to {
  opacity: 0;
  transform: scale3d(2, 0.5, 1) translate3d(-400px, 0, 0);
}
</style>
