<template>
  <div data-test-id="help-trigger" @click="onShowHelpModal">
    <span class="icon-text has-text-info">
      <span class="icon">
        <i class="fas fa-info-circle"></i>
      </span>
      <span>Info</span>
    </span>
  </div>
  <body class="A4 landscape">
    <div
      v-if="report && !loadingReport"
      style="background-color: white"
      id="report-container"
      ref="reportContainer"
    >
      <div class="sheet" v-for="tocPage in reportTocPageOffset - 1" :key="tocPage">
        <div class="report-toc-page content">
          <div class="report-title">
            <h2>
              {{ slugToTitle(report?.reportTitle) }}
            </h2>
          </div>
          <div class="toc">
            <ol>
              <li
                :key="heading.id"
                v-for="heading in reportTocOrganizedAndPaged.slice(
                  (tocPage - 1) * 10,
                  tocPage * 10
                )"
              >
                <h4 style="text-align: left">{{ heading.sectionHeader }}</h4>
                <p style="text-align: left">
                  {{ createPageNumberingTemplate(heading.page) }}
                </p>
              </li>
            </ol>
          </div>
          <footer style="display: none"></footer>
        </div>
      </div>
      <div
        class="sheet"
        :key="index"
        :id="`chapter-${index}-container`"
        v-for="(sections, index) in printReadyReport"
      >
        <div class="sheet__content" ref="renderedReportContainer">
          <header v-if="getGroupSize(sections) !== 1000">
            <p>
              <strong>{{ report?.organization ? report?.organization : report?.username }}</strong>
            </p>
          </header>
          <div
            class="group-container"
            :class="{
              'group-container--1k-scale': getGroupSize(sections) == 1000,
              'group-container--800-scale': getGroupSize(sections) == 800,
              'group-container--600-scale': getGroupSize(sections) == 600,
              'group-container--400-scale': getGroupSize(sections) == 400,
              'group-container--200-scale': getGroupSize(sections) == 200
            }"
          >
            <div
              :key="'printable-' + index"
              v-for="(curr, index) in sections"
              style="width: 1400px"
            >
              <ReportRenderPrint
                :headingFont="report.headingFont"
                :bodyFont="report.bodyFont"
                :sectionBgColor="curr.sectionBgColor"
                :generatedJson="curr.generatedJson"
                :sectionBgImage="curr.sectionBgImage"
                :sectionBgImageSize="curr?.sectionBgImageConfig?.size"
                :sectionHeight="curr.sectionHeight"
                :sectionFlex="curr.sectionFlex"
                :childSections="curr.sections"
                :sectionBgImageAlt="curr.sectionBgImageConfig.bgAlt"
                :sectionBgImageTitle="curr.sectionBgImageConfig.bgTitle"
                :sectionOrder="curr.order"
                :isCoverSection="curr.isCoverSection"
                :isLastSection="false"
                :sectionId="curr.id"
                :isSectionContainer="curr.isSectionContainer"
                :includeInWeb="curr.includeInWeb"
                :includeInPrint="curr.includeInPrint"
              />
            </div>
          </div>
          <footer>
            <p>
              <strong>{{ slugToTitle(report?.reportTitle) }}</strong>
            </p>
          </footer>
        </div>

        <!--      <div v-if="currentSection.isLastSection" ref="yearlyFooterContainer">
        <YearlyReportFooter :showFooter="showFooterPublished" />
      </div> -->
      </div>
    </div>
  </body>
</template>

<script>
import { onMounted, ref, computed, inject, watchEffect, onBeforeUnmount, onBeforeUpdate } from 'vue'
import { useRouter, useRoute } from 'vue-router'

import reportView from '@/composables/reportView'
import { setElRefs, clearRefs } from '@/composables/setElRefs'
import CommonModals from '@/composables/commonModals'

import CoverSectionContainer from '@/components/CoverSectionContainer'
import ReportRenderPrint from '@/components/ReportRenderPrint'
import YearlyReportFooter from '@/components/YearlyReportFooter'
import ChromeInstructions from '@/views/viewer/PrintInstructions/ChromeInstructions'

const PREVIEW = 'PREVIEW'
const PUBLISHED = 'PUBLISHED'
export default {
  components: {
    CoverSectionContainer,
    YearlyReportFooter,
    ReportRenderPrint
  },
  setup() {
    const Alert = inject('$alert')
    const previewContainer = ref(null)
    const chapterContainers = ref([])
    const renderedReportContainer = ref(null)
    const yearlyFooterContainer = ref(null)
    const router = useRouter()
    const { $vfm, showHelpModal, showLoadingModal } = CommonModals()
    const {
      showNextFooter,
      nextPageTrigger,
      resizeViewerElHeight,
      report,
      loadingReport,
      currentSectionQuery,
      currentSection,
      nextSection,
      initializePreivewReportRoute,
      initializeReportRoute,
      showFooterPreview,
      reportContainer,
      onNextChapter,
      nextSlideFooter,
      getGroupSize,

      reorganizeReportForPrint,
      loadFonts,
      getTocWithPages,
      printReadyReport,
      tocByViewTypeFilter
    } = reportView(PUBLISHED)
    const footerFont = computed(() => {
      return report?.value?.bodyFont || 'inter'
    })
    const reportTocOrganizedUnpaged = computed(() => {
      return tocByViewTypeFilter(report.value, 'PRINT')
    })
    const numberOfTocPagesForOffset = computed(() => {
      return reportTocOrganizedUnpaged.value.length
    })
    const reportTocPageOffset = computed(() => {
      return Math.ceil(numberOfTocPagesForOffset.value / 10 + 1)
    })
    const reportTocOrganizedAndPaged = computed(() => {
      return getTocWithPages(
        reportTocOrganizedUnpaged.value,
        printReadyReport.value,
        reportTocPageOffset.value
      )
    })
    function createPageNumberingTemplate(page) {
      let multiplier = Math.floor(`${150 - ('page ' + page.toString()).length}`)

      return '.'.repeat(multiplier) + `${'Page ' + page}`
    }

    async function onShowHelpModal() {
      showHelpModal(
        'Report Print Preview',
        report.title,
        'Printing to PDF is best used in chrome',
        {},
        {},
        {
          'modal-content-1': {
            component: ChromeInstructions
          }
        }
      )
    }
    function slugToTitle(val) {
      if (!val) {
        return 'Report'
      }
      let slug = val
        .replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>{}[\]\\/]/gi, ' ')
        .trim()
        .split(' ')

      return slug.map((w) => `${w.substr(0, 1).toUpperCase()}${w.substr(1, w.length)}`).join(' ')
    }
    function setTitle() {
      window.document.title = report?.value.reportSlug
    }
    function unSetTitle() {
      window.document.title = 'Yearly'
    }

    onMounted(async () => {
      try {
        await initializeReportRoute()

        if (report.value.subscribedPlan !== 'premium_plus') {
          await showHelpModal(
            'We Apologize!',
            'This feature is not available for this report',
            'If you own this report upgrade to Yearly Premium',
            {},
            {
              closed: () => {
                router.push({
                  name: 'ReportViewerPage',
                  params: { userName: report.value.username, reportSlug: report.value.reportSlug }
                })
              }
            }
          )
          return
        }

        window.addEventListener('beforeprint', setTitle)

        window.addEventListener('afterprint', unSetTitle)

        if (report.value?.headingFontIsCustom) {
          loadFonts(report.value.headingFontUrl, true)
        }
        if (report.value?.bodyFontIsCustom) {
          loadFonts(report.value.bodyFontUrl, true)
        }

        reorganizeReportForPrint(report.value)
        await showLoadingModal('Loading', 'Grabbing Report for Preview')
        await onShowHelpModal()
      } catch (e) {
        Alert.alert({ message: 'An error occured fetching Report', timeout: 3000, type: 'error' })
      } finally {
        $vfm.hide('LoadingModal')
      }
    })
    watchEffect(() => {})
    onBeforeUpdate(() => {})
    onBeforeUnmount(() => {
      window.removeEventListener('afterprint', unSetTitle)
      window.removeEventListener('beforeprint', setTitle)
    })

    return {
      report,
      loadingReport,
      currentSection,
      currentSectionQuery,
      nextPageTrigger,
      showNextFooter,
      nextSection,
      getTocWithPages,
      previewContainer,
      chapterContainers,
      onNextChapter,
      reportContainer,
      nextSlideFooter,
      resizeViewerElHeight,
      yearlyFooterContainer,
      renderedReportContainer,
      setElRefs,
      printReadyReport,
      getGroupSize,
      tocByViewTypeFilter,
      showHelpModal,
      onShowHelpModal,
      footerFont,
      reportTocOrganizedAndPaged,
      numberOfTocPagesForOffset,
      reportTocPageOffset,
      createPageNumberingTemplate,
      slugToTitle
    }
  }
}
</script>

<style lang="scss" scoped>
@page {
  margin: 0;
}
body {
  margin: 0;
  counter-reset: sheet;
}
.sheet {
  margin: 0;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  page-break-after: always;
  counter-increment: sheet;
}

/** Paper sizes **/
body.A3 .sheet {
  width: 297mm;
  height: 419mm;
}
body.A3.landscape .sheet {
  width: 420mm;
  height: 296mm;
}
body.A4 .sheet {
  width: 210mm;
  height: 296mm;
}
body.A4.landscape .sheet {
  width: 297mm;
  height: 209mm;
}
body.A5 .sheet {
  width: 148mm;
  height: 209mm;
}
body.A5.landscape .sheet {
  width: 210mm;
  height: 147mm;
}
body.letter .sheet {
  width: 216mm;
  height: 279mm;
}
body.letter.landscape .sheet {
  width: 280mm;
  height: 215mm;
}
body.legal .sheet {
  width: 216mm;
  height: 356mm;
}
body.legal.landscape .sheet {
  width: 357mm;
  height: 215mm;
}

/** Padding area **/
.sheet.padding-10mm {
  padding: 10mm;
}
.sheet.padding-10mm--left-right {
  padding: 0 10mm;
}
.sheet.padding-10mm--top-bottom {
  padding: 10mm 0mm;
}
.sheet.padding-15mm {
  padding: 15mm;
}
.sheet.padding-20mm {
  padding: 20mm;
}
.sheet.padding-25mm {
  padding: 25mm;
}
footer:before {
  /*   display: flex;
  align-items: center; */
  position: absolute;
  top: 30%;
  margin: auto 2rem;
  right: 0;
  float: right;
  content: 'Page ' counter(sheet);
}
header,
footer {
  font-family: v-bind(footerFont);
}

footer {
  > p {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  border-top: solid 1px lightgray;
  height: 75px;
}
.group-container + footer {
  position: relative;
  bottom: -110px;
}
.group-container.group-container--200-scale + footer {
  position: relative;
  bottom: -440px;
}
.group-container.group-container--400-scale + footer {
  position: relative;
  bottom: -240px;
}
.group-container.group-container--600-scale + footer {
  position: relative;
  bottom: -40px;
}
.group-container.group-container--800-scale + footer {
  position: relative;
  bottom: 159px;
}
.group-container.group-container--1k-scale + footer {
  /* position: relative;
  bottom: 290px; */
  display: none;
}

header {
  display: flex;
  justify-content: center;
  align-items: center;
  > p {
    text-align: center;
  }

  border-top: solid 1px lightgray;
  height: 75px;
}

.group-container.group-container--800-scale + header {
  position: relative;
  bottom: 90px;
}
.group-container.group-container--1k-scale + header {
  /* position: relative;
  bottom: 290px; */
  display: none;
}
/** For screen preview **/
@media screen {
  body {
    background: #e0e0e0;
  }
  .sheet {
    background: white;
    box-shadow: 0 0.5mm 2mm rgba(0, 0, 0, 0.3);
    margin: 5mm auto;
  }
  .group-container {
    &--1k-scale {
      transform: scale(0.801, 0.79);
      transform-origin: top left;
    }
    &--800-scale {
      transform: scale(0.801);
      transform-origin: top left;
    }
    &--600-scale {
      transform: scale(0.801);
      transform-origin: top left;
    }
    &--400-scale {
      transform: scale(0.801);
      transform-origin: top left;
    }
    &--200-scale {
      transform: scale(0.801);
      transform-origin: top left;
    }
  }
}

/** Fix for Chrome issue #273306 **/
@media print {
  div[data-test-id='help-trigger'] {
    display: none;
  }
  body.A3.landscape {
    width: 420mm;
  }
  body.A3,
  body.A4.landscape {
    width: 297mm;
  }
  body.A4,
  body.A5.landscape {
    width: 210mm;
  }
  body.A5 {
    width: 148mm;
  }
  body.letter,
  body.legal {
    width: 216mm;
  }
  body.letter.landscape {
    width: 280mm;
  }
  body.legal.landscape {
    width: 357mm;
  }
  .group-container {
    &--1k-scale {
      transform: scale(0.801, 0.79);
      transform-origin: top left;
    }
    &--800-scale {
      transform: scale(0.801);
      transform-origin: top left;
    }
    &--600-scale {
      transform: scale(0.801);
      transform-origin: top left;
    }
    &--400-scale {
      transform: scale(0.801);
      transform-origin: top left;
    }
  }

  /*   footer:before {
    content: 'Page ' counter(sheet);
  }
  .group-container + footer {
    position: relative;
    bottom: -138px;
    height: 50px;
    background-color: red;
  }
  .group-container.group-container--800-scale + footer {
    position: relative;
    bottom: 61px;
    height: 50px;
    background-color: red;
  }
  .group-container.group-container--1k-scale + footer {
    position: relative;
    bottom: 261px;
    height: 50px;
    background-color: red;
  } */
}
.report-toc-page {
  padding: 2rem 8rem;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  align-content: left;
  font-family: v-bind(footerFont);
}
.toc {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.report-title {
  text-align: center;
}
</style>
