<template>
  <transition name="">
    <footer
      ref="nextSlideFooter"
      id="next-slide-footer"
      v-if="showNextFooter && nextSection"
      class="footer next-slide-footer"
      :class="{
        'has-background-dark': !nextSection.sectionBgImage || !nextSection.sectionBgImage.length
      }"
      :style="getFooterStyle"
    >
      <div
        @click="goToNextSection"
        class="has-text-centered"
        style="cursor: pointer; display: flex; flex-direction: column; position: relative; top: 95%"
      >
        <p class="has-text-warning-light">NEXT UP</p>
        <p class="has-text-warning-light subtitle">{{ nextSection?.sectionHeader }}</p>
        <div class="margin-top:auto;">
          <p class="help" style="color: white">Click to Continue</p>

          <i class="fas fa-caret-down fa-lg bounce-next"></i>
        </div>
      </div>
    </footer>
  </transition>
</template>

<script>
import { computed, toRefs, watchEffect, ref } from 'vue'
import reportView from '@/composables/reportView'
import { reportSectionFunctions } from '@/composables/reportFunctions'

export default {
  name: 'UpNextFooter',
  props: {
    showNextFooter: {
      type: Boolean,
      default: true
    },
    nextSection: {
      type: Object,
      default: null
    }
  },
  components: {},
  emits: ['go-to-next-section', 'next-slide-footer-height'],
  setup(props, { emit }) {
    const { nextSection } = toRefs(props)
    const { nextSlideFooter } = reportView('')
    const { changeToOptimizedImageUrl } = reportSectionFunctions(props, emit)
    const getFooterStyle = computed(() => {
      let style = {
        backgroundImage: `url('${changeToOptimizedImageUrl(nextSection.value.sectionBgImage)}')`,
        backgroundSize:
          nextSection.value.sectionBgImageConfig.size == 'stretch'
            ? '100% 100%'
            : nextSection.value.sectionBgImageConfig.size,
        'box-shadow':
          nextSection.value.sectionBgImage && nextSection.value.sectionBgImage.length
            ? '0rem 0rem 650px 80px black inset'
            : 'none'
      }
      console.log(`url('${changeToOptimizedImageUrl(nextSection.value.sectionBgImage)}')`)
      return style
    })
    function goToNextSection() {
      emit('go-to-next-section')
    }
    const nextSlideFooterHeight = computed(() => {
      if (nextSlideFooter.value) {
        return nextSlideFooter.value.getBoundingClientRect().height
      }
      return 0
    })
    const nextSlideFooterWidth = computed(() => {
      if (nextSlideFooter.value) {
        return nextSlideFooter.value.getBoundingClientRect().width
      }
      return 0
    })
    watchEffect(() => {
      emit('next-slide-footer-height', nextSlideFooterHeight.value)
    })

    return {
      goToNextSection,
      nextSlideFooter,
      nextSlideFooterHeight,
      nextSlideFooterWidth,
      getFooterStyle
    }
  }
}
</script>

<style lang="scss" scoped>
.next-slide-footer {
  font-family: 'Raleway', 'museo';
  @include until(768px) {
    height: 100vh;
  }
  @include from(769px) {
    height: 500px;
  }

  background-repeat: no-repeat;
  background-position: center;
}
.slide-fade-enter-active {
  // transition: all 0.8s ease-out;
}

.slide-fade-leave-active {
  // transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  opacity: 0;
  transform: translateX(200px);
}

#next-slide-footer {
  @include mobile {
  }
}

.bounce-next {
  animation: pulse 1s infinite ease-in-out alternate;
}

.pulse {
}
@keyframes pulse {
  from {
    transform: scale(0.8);
    opacity: 0;
    color: transparent;
  }
  to {
    transform: scale(1.5);
    opacity: 1;
    color: white;
  }
}
</style>
