<template>
  <div
    v-if="!isSectionContainer && includeInPrint"
    :ref="(el) => setElRefs(el, sectionContainerRender, sectionOrder)"
    :id="determineSectionId()"
    :style="getPreviewingStyle"
    :aria-label="sectionBgImageAlt"
    class="section-container section-container--view-mode"
    :class="{
      'module-content-outline': !isCoverSection,
      'chapter-section': isCoverSection,
      'module-section': !isCoverSection
    }"
  >
    <img
      style="position: absolute; height: 100%; width: 100%; opacity: 0;"
      :title="sectionBgImageTitle"
      :alt="sectionBgImageAlt"
    />
    <!--     <div
      style="position: absolute; height: 100%; width: 100%"
      :style="`background-color:${bgColor};`"
    ></div> -->
    <markup-input
      :isEnabled="false"
      :generatedJson="generatedJson"
      :extensions="extensions"
      :headingFont="headingFont"
      :bodyFont="bodyFont"
    ></markup-input>
  </div>

  <div
    class="columns is-mobile is-gapless section-container__module-wrapper"
    :class="{ 'is-multiline': !childSectionsInline }"
    tabindex="0"
    v-if="
      childSections &&
      childSections.length &&
      !hideChapterModules &&
      !isCoverSection &&
      includeInPrint
    "
  >
    <div
      :class="`column is-${childSection.sectionFlex}`"
      class="section-container__module-content"
      :key="childSection.id"
      v-for="childSection in childSections"
    >
      <ReportRenderPrint
        :headingFont="headingFont"
        :bodyFont="bodyFont"
        :sectionBgColor="childSection.sectionBgColor"
        :generatedJson="childSection.generatedJson"
        :sectionBgImage="childSection.sectionBgImage"
        :sectionHeight="childSection.sectionHeight"
        :sectionFlex="childSection.sectionFlex"
        :childSections="childSection.sections"
        :isSectionContainer="childSection.isSectionContainer"
        :childSectionsInline="childSection.isInline"
        :sectionOrder="childSection.order"
        :isLastSection="childSection.order == childSections.length"
        :sectionBgImageSize="childSection.sectionBgImageConfig.size"
        :sectionId="childSection.id"
        :includeInWeb="childSection.includeInWeb"
        :includeInPrint="childSection.includeInPrint"
      />
    </div>
  </div>
  <!--   </div> -->
</template>

<script>
import {
  ref,
  toRefs,
  computed,
  watchEffect,
  onMounted,
  onBeforeUnmount,
  onBeforeUpdate,
  watch
} from 'vue'

import { generateHTML } from '@tiptap/vue-3'

import EditorConfig from '@/composables/editorConfig'
import { setElRefs, clearRefs } from '@/composables/setElRefs'
import { reportSectionFunctions } from '@/composables/reportFunctions'

import FormFieldVue from '@/components/FormField'
import MarkupInput from './MarkupInput.vue'

export default {
  name: 'ReportRender',
  components: {
    FormFieldVue,
    MarkupInput
  },
  props: {
    sectionOrder: {
      type: Number
    },
    isLastSection: {
      type: Boolean
    },
    isSectionContainer: {
      type: Boolean,
      default: false
    },
    childSections: {
      type: Array,
      default: () => []
    },
    sectionHeight: {},
    sectionFlex: {},
    headingFont: {
      type: String,
      default: 'inter'
    },
    bodyFont: {
      type: String,
      default: 'inter'
    },

    sectionBgColor: {
      type: String,
      default: '#FF0005'
    },
    scale: {
      type: Array,
      default: () => []
    },
    sectionBgImage: {},
    isCoverSection: {
      type: Boolean
    },
    generatedJson: {},
    childSectionsInline: {
      type: Boolean,
      default: false
    },
    sectionBgImageAlt: {
      type: String,
      default: ''
    },
    sectionBgImageTitle: {
      type: String,
      default: ''
    },
    hideChapterModules: {
      type: Boolean,
      default: false
    },
    sectionBgImageSize: {},
    sectionId: {
      type: String
    },
    includeInWeb: {
      type: Boolean,
      default: true
    },
    includeInPrint: {
      type: Boolean,
      default: true
    }
  },
  setup(props, { emit }) {
    const {
      bodyFont,
      headingFont,
      sectionBgColor,
      sectionHeight,
      scale,
      sectionBgImage,
      sectionBgImageSize,
      isCoverSection,
      isSectionContainer,
      sectionOrder,
      sectionId
    } = toRefs(props, { emit })
    const { changeToOptimizedImageUrl } = reportSectionFunctions(props, emit)
    const coverSectionContainer = ref(null)
    const chapterModuleContainers = ref([])
    const sectionContainerRender = ref([])

    const { extensions } = EditorConfig(bodyFont.value, headingFont.value)
    onMounted(() => {})

    const getPreviewingStyle = computed(() => {
      return `
        min-height:${sectionHeight.value}px;
        max-height:${sectionHeight.value}px;
        height:${sectionHeight.value}px;
        overflow: hidden;
       background-color:${sectionBgColor.value};
        background-image: url('${changeToOptimizedImageUrl(sectionBgImage.value)}');
        background-size:${
          sectionBgImageSize.value == 'stretch' ? '100% 100%' : sectionBgImageSize.value
        };
        background-position:center;
        background-repeat:no-repeat;
        transform-origing:top left;
     
      
       
     
      

      `
    })
    const bgColor = computed(() => {
      try {
        let color = sectionBgColor.value || '255,255,255,0'
        color = color.replace('rgba', '')
        color = color.replace('rgb', '')
        color = color.replace('(', '')
        color = color.replace(')', '')

        const codes = color.split(',')

        //const newColor = codes.length == 4 ? sectionBgColor.value : `rbga(${codes.join(',')},0)`
        const newColor = codes.length == 4 ? sectionBgColor.value : `rbga(${codes.join(',')},0)`
        return newColor
      } catch (e) {
        console.log(e)
        return sectionBgColor.value
      }
    })
    const position = computed(() => {
      return sectionContainerRender.value?.offsetTop
    })
    function determineSectionId() {
      if (isCoverSection.value) {
        return `chapter-${sectionId.value}`
      } else if (!isSectionContainer.value && !isCoverSection.value) {
        return `module-conatiner-${sectionId.value}`
      } else {
        return `module-${sectionId.value}`
      }
    }

    onBeforeUpdate(() => {
      clearRefs(sectionContainerRender.value)
    })

    onBeforeUnmount(() => {})
    return {
      position,
      extensions,
      generateHTML,
      coverSectionContainer,
      getPreviewingStyle,
      setElRefs,
      chapterModuleContainers,
      sectionContainerRender,
      determineSectionId,
      bgColor
    }
  }
}
</script>

<style lang="scss" scoped>
.editor-menu {
  display: none;
}

.section-container {
  position: relative;
}

.section-container--view-mode {
  :deep(.ProseMirror) {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
.section-container--enable-scrolling {
  overflow-y: scroll !important;
}
</style>
