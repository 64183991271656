<template>
  <div>
    <button class="button is-centered" @click="printPage">Print</button>
    <div class="card">
      <div class="card-image">
        <figure class="image is-3by1">
          <img
            :src="`${baseLocation}/images/instructions/chrome-destination.png`"
            alt="set chrome destination image"
          />
        </figure>
      </div>
      <div class="card-content">
        <div class="content">
          <span class="help"
            >Select Save as PDF to save the report as a pdf (alternatively select printer)</span
          >
          <br />
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-image">
        <figure class="image is-3by1">
          <img
            :src="`${baseLocation}/images/instructions/chrome-layout.png`"
            alt="set chrome layout image"
          />
        </figure>
      </div>
      <div class="card-content">
        <div class="content">
          <span class="help"
            >Select Landscape as the Layout option to set the page to landscape mode.
          </span>
          <br />
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-image">
        <figure class="image is-3by1">
          <img
            :src="`${baseLocation}/images/instructions/chrome-paper-size.png`"
            alt="set chrome paper size image"
          />
        </figure>
      </div>
      <div class="card-content">
        <div class="content">
          <span class="help">
            Select A4 for paper size as the content is resized for A4 paper
          </span>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-image">
        <figure class="image is-3by1">
          <img
            :src="`${baseLocation}/images/instructions/chrome-margins-scale.png`"
            alt="set default margins and scale image"
          />
        </figure>
      </div>
      <div class="card-content">
        <div class="content">
          <span class="help">
            Select Default for Margins and Scale to maintain the app settings
          </span>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-image">
        <figure class="image is-3by1">
          <img
            :src="`${baseLocation}/images/instructions/chrome-bg-graphics.png`"
            alt="set chrome bg graphics image"
          />
        </figure>
      </div>
      <div class="card-content">
        <div class="content">
          <span class="help">
            Check Header&Footers + Background Graphics (if available as an option)
          </span>

          <br />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { inject } from 'vue'
export default {
  setup() {
    const $vfm = inject('$vfm')
    async function printPage() {
      await $vfm.hideAll()
      window.print()
    }
    return {
      baseLocation: window.location.origin,
      printPage
    }
  }
}
</script>

<style lang="scss" scoped></style>
