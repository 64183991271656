<template>
  <nav class="navbar" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">
      <a
        role="button"
        class="navbar-burger"
        aria-label="menu"
        aria-expanded="false"
        @click="onExpandMenu"
      >
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>
    <div ref="chapterToggle" class="navbar-menu is-mobile" :class="{ 'is-active': expandMenu }">
      <ul class="menu-list">
        <li
          :key="item.id"
          @click="$emit('selected-section', item.id)"
          :class="{ 'is-active': isActive }"
          v-for="item in items"
        >
          <span>{{ item.section_header }}</span>
        </li>
      </ul>
    </div>
  </nav>

  <!--   <aside class="menu">

  </aside> -->
</template>

<script>
import { ref } from 'vue'

export default {
  name: 'YearlyReportFooter',
  props: {
    isActive: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      default: () => []
    }
  },
  components: {},
  emits: ['selected-section'],
  setup() {
    const chapterToggle = ref(null)
    const expandMenu = ref(false)
    function onExpandMenu() {
      expandMenu.value = !expandMenu.value
    }
    return {
      chapterToggle,
      expandMenu,
      onExpandMenu
    }
  }
}
</script>

<style lang="scss" scoped>
.navbar {
  position: fixed !important;
  right: 0px;
  background-color: transparent !important;
}
.navbar-burger {
  background-color: transparent;
}
#yearly-footer {
  background: #0a0a0a;
  border-radius: 10px;

  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;

  > span {
    margin-right: 1rem;
    margin-top: 2.2rem;
  }
  > figure {
    margin-top: 3rem;
  }
}
</style>
